export const getErrorMessage = (error) => {
    if (!error) {
        return ""
    }

    switch (error?.type) {
        case "required":
            return "This field is required"
        default:
            return error?.message || "This field has an error"
    }
}

export const validationPatterns = {
    email: {
        value:
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message: "Invalid email address",
    },
}
