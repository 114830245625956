import {PageContainer} from "../components/PageContainer";
import {Logo} from "../components/Logo";
import {BoldText} from "../components/BoldText";
import {TermsAndConditionsModal} from "../components/TermsAndConditionsModal";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {Button, Group, Stack, Text, Title, Image, Flex} from "@mantine/core";
import bg from '../assets/images/bg1.png'
import {useResponsive} from "../hooks/utils/useResponsive";
import {Footer} from "../components/Footer";

export const Home = () => {
    const navigate = useNavigate()
    const {isMobileView} = useResponsive()

    const [open, setOpen] = useState(false)

    const onAgreed = () => {
        navigate("/get-started")
    }

    return (
        <>
            <TermsAndConditionsModal open={open} setOpen={setOpen} onAgreed={onAgreed}/>

            <PageContainer className={'pt-[48px]'} showHeader={false}>
                <Logo isDark/>

                <Flex
                    direction={!isMobileView ? 'row' : 'column-reverse'}
                    align={isMobileView ? 'flex-start' : 'center'}
                    gap={'md'}
                >
                    <Stack flex={1}>
                        <div className={'mt-12'}>
                            <Title order={1} fw={'bolder'}>
                                AI SKIN ANALYSIS
                            </Title>

                            <Text fw={'bold'} size={'lg'}>
                                Get real time analysis of your skin and recommendations for better skin health.
                            </Text>
                        </div>

                        <div className={'flex flex-row mt-8'}>
                            <Button
                                variant={'filled'}
                                onClick={() => {
                                    setOpen(true)
                                }}
                                size={'lg'}
                            >
                                Get Started
                            </Button>

                            <Button
                                variant={'outline'}
                                className={'ml-8'}
                                onClick={() => {
                                    window.location.assign("https://tuibeauty.com")
                                }}
                                size={'lg'}
                            >
                                Go to shop
                            </Button>
                        </div>
                    </Stack>
                    <Stack flex={1} mt={isMobileView ? 64 : 0}>
                        <Image
                            radius="md"
                            src={bg}
                            h={400}
                            mah={600}
                            w="auto"
                            fit={"cover"}
                            style={{height: isMobileView ? 400 : 200, width: '100%'}}
                        />
                    </Stack>
                </Flex>
            </PageContainer>
            <Footer/>
        </>

    )
}
