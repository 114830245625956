import {Text} from '@mantine/core'

export const ResultsItem = ({title, index, isLast}) => {
    return (
        <div
            className={'flex flex-row justify-between w-full py-3'}
            style={{
                ...(!isLast ? {
                    borderBottom: 'solid',
                    borderBottomWidth: 1,
                    borderBottomColor: "#D4D4D4",
                } : null)
            }}
        >
            <Text fw={'bold'} size={'md'}>
                {title}
            </Text>

            <div>
                <Text size={'sm'}>
                    {index}
                </Text>
            </div>

        </div>
    )
}
