import {AppShell} from '@mantine/core';
import {Outlet} from "react-router-dom";

export function MainLayout() {
    return (
        <AppShell
            padding="md"
            footer={{
                offset: true
            }}
        >
            <AppShell.Main>
                <Outlet/>
            </AppShell.Main>
        </AppShell>
    );
}
