import {PageContainer} from "../components/PageContainer";
import {useNavigate, useParams} from "react-router-dom";
import {useFindSkinAnalysisResults} from "../hooks/api/useSkinAnalysisApi";
import {useEffect, useState} from "react";
import {ANALYSIS_STATUS} from "../constants/status";
import {LoadingElement} from "../components/LoadingElement";
import {ResultsItem} from "../components/ResultsItem";
import {ResultsHighlight} from "../components/ResultHighlights";
import {Divider} from "@mui/material";
import {isEmpty} from "lodash";
import {Card, Stack, Text, Title} from '@mantine/core'
import {Logo} from "../components/Logo";
import {CenteredLogoWithTitle} from "../components/CenteredLogoWithTitle";

export const Results = () => {
    const {id} = useParams()
    const navigate = useNavigate();
    const [enableFetch, setEnableFetch] = useState(true)

    const findAnalysisQuery = useFindSkinAnalysisResults(id, enableFetch)
    const skinAnalysis = findAnalysisQuery.data?.data

    const isPending = skinAnalysis?.status === ANALYSIS_STATUS.PENDING
    const isQueued = skinAnalysis?.status === ANALYSIS_STATUS.QUEUED
    const isReady = skinAnalysis?.status === ANALYSIS_STATUS.READY
    const isError = skinAnalysis?.status === ANALYSIS_STATUS.ERROR

    useEffect(() => {
        if (skinAnalysis?.status === ANALYSIS_STATUS.READY || skinAnalysis?.status === ANALYSIS_STATUS.ERROR) {
            setEnableFetch(false)
        }
    }, [skinAnalysis?.status, id])

    const results = JSON.parse(skinAnalysis?.score_info || "{}") || {}
    const areasOfFocus = JSON.parse(skinAnalysis?.areas_of_focus || "[]") || []
    const strengths = JSON.parse(skinAnalysis?.strengths || "[]") || []
    const moderate = JSON.parse(skinAnalysis?.moderate || "[]") || []


    return (
        <PageContainer
            showClose
            onClose={() => {
                navigate("/")
            }}
        >
            {(skinAnalysis && !findAnalysisQuery.isLoading) &&
                <div className="flex flex-col desktop:flex-row mt-8 pb-8 items-start">
                    <div className="basis-1/3 flex items-center flex-col">
                        <img
                            src={skinAnalysis?.face_data}
                            alt={"selfie"}
                            style={{
                                maxHeight: 500,
                                maxWidth: '100%'
                            }}
                        />
                    </div>


                    <div className="flex flex-col items-center basis-2/3 pl-4">
                        <div className={'flex flex-col items-center p-8 w-full max-w-lg'}>
                            <Card
                                variant={'subtle'}
                                withBorder
                                shadow="sm"
                                radius="md"
                                style={{
                                    width: '100%'
                                }}
                            >
                                <Stack>
                                    <Stack justify={'center'} align={'center'} mb={'md'}>
                                        <CenteredLogoWithTitle
                                            label={
                                                <>
                                                    {isPending && "Awaiting payment confirmation"}
                                                    {isReady && "Your results are ready!"}
                                                    {isQueued && "Your results are being processed!"}
                                                    {isError && "We encountered an error"}
                                                </>
                                            }
                                        />

                                        {(isPending || isQueued) &&
                                            <LoadingElement
                                                title={"Preparing your results"}
                                                subTitle={"Please keep this window open"}
                                            />
                                        }

                                        {isError &&
                                            <Text md={'md'} style={{textAlign: 'center'}}>
                                                {skinAnalysis?.error_message}
                                            </Text>
                                        }

                                        {isReady &&
                                            <>
                                                <Text fw={'bold'} className={'text-center'}>
                                                    Overall Results
                                                </Text>

                                                <ResultsItem title={"Acne"} index={results?.acne_score}/>
                                                <ResultsItem title={"Roughness"} index={results?.rough_score}/>
                                                <ResultsItem title={"Hyperpigmentation"}
                                                             index={results?.melanin_score}/>
                                                <ResultsItem title={"Pores"} index={results?.pores_score}/>
                                                <ResultsItem title={"Hydration"} index={results?.water_score}/>
                                                <ResultsItem title={"Dark Circles"} index={results?.dark_circle_score}/>
                                                <ResultsItem title={"Blackheads"} index={results?.blackhead_score}
                                                             isLast/>


                                                <ResultsHighlight
                                                    title={"Your skin strengths"}
                                                    list={strengths}
                                                    style={{
                                                        marginTop: 24,
                                                        marginBottom: 24,
                                                    }}
                                                    isPositive
                                                />

                                                <Divider/>

                                                {!isEmpty(moderate) &&
                                                    <>
                                                        <ResultsHighlight
                                                            title={"Balanced areas (room for improvement)"}
                                                            list={moderate}
                                                            style={{
                                                                marginTop: 24,
                                                                marginBottom: 24,
                                                            }}
                                                        />

                                                        <Divider/>
                                                    </>
                                                }

                                                <ResultsHighlight
                                                    title={"Your areas of focus"}
                                                    list={areasOfFocus}
                                                    style={{
                                                        marginBottom: 24,
                                                    }}
                                                    isPositive={false}
                                                />

                                                {/*<Button className={'w-full'}>*/}
                                                {/*    See my recommendations*/}
                                                {/*</Button>*/}

                                                {/*<Button*/}
                                                {/*    variant={'secondary'}*/}
                                                {/*    className={'w-full mt-4'}*/}
                                                {/*    onClick={() => {*/}
                                                {/*        navigate(`/email-recommendations/${id}`)*/}
                                                {/*    }}*/}
                                                {/*>*/}
                                                {/*    Email my recommendations*/}
                                                {/*</Button>*/}

                                            </>
                                        }

                                    </Stack>
                                </Stack>
                            </Card>
                        </div>
                    </div>
                </div>
            }

        </PageContainer>
    )
}
