import {Group, Radio, Stack, Text} from '@mantine/core';
import classes from './radio.css';

export const RadioSelector = ({data = [], value, setValue, label, hint}) => {

    const cards = data.map((item) => (
        <Radio.Card className={classes.root} radius="md" value={item.value} key={item.value} p={'md'}>
            <Group wrap="nowrap" align="flex-start">
                <Radio.Indicator/>
                <div>
                    <Text fw={'bold'} className={classes.label}>{item.name}</Text>
                    <Text className={classes.description}>{item.description}</Text>
                </div>
                {item.component && item.component}
            </Group>
        </Radio.Card>
    ));

    return (
        <>
            <Radio.Group
                value={value}
                onChange={setValue}
                label={label}
                description={hint}
            >
                <Stack pt="md" gap="xs">
                    {cards}
                </Stack>
            </Radio.Group>
        </>
    );
}
