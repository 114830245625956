import {Box, TextInput as MantineTextInput} from "@mantine/core";
import {forwardRef} from "react";
import {Controller} from "react-hook-form";
import {getErrorMessage} from "./forms.utils";

export const TextInput = forwardRef(({
                                         label,
                                         hint,
                                         placeholder,
                                         required = false,
                                         errors,
                                         name,
                                         type = 'text',
                                         mb = 16,
                                         control,
                                         rules,
                                         ...rest
                                     }, ref) => {
    return (
        <Box mb={mb}>
            <Controller
                name={name}
                control={control}
                rules={{required, ...rules}}
                render={({field: {onChange, value, ...restFields}}) => (
                    <MantineTextInput
                        ref={ref}
                        withAsterisk={required}
                        label={label}
                        description={hint}
                        placeholder={placeholder}
                        error={getErrorMessage(errors?.[name])}
                        type={type}
                        value={value}
                        onChange={(_value) => onChange?.(_value.target.value)}
                        {...restFields}
                        {...rest}
                    />
                )}
            />


        </Box>
    )
})
