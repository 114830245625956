import {PageContainer} from "../components/PageContainer";
import {useNavigate, useParams} from "react-router-dom";
import {useFindSkinAnalysis} from "../hooks/api/useSkinAnalysisApi";
import {PaymentForm} from "../components/PaymentForm";
import {useState} from "react";
import {ConfirmationBox} from "../components/ConfirmationBox";
import {Card, Center, Stack} from "@mantine/core";

export const Payment = () => {
    const navigate = useNavigate();

    const {id} = useParams()
    const analysisQuery = useFindSkinAnalysis(id)

    const [paymentComplete, setPaymentCompleted] = useState(false)

    const analysis = analysisQuery?.data?.data || {}

    const onSuccess = () => {
        setPaymentCompleted(true)
    }

    const onFailed = () => {
        setPaymentCompleted(false)
    }

    return (
        <PageContainer
            showClose
            onClose={() => {
                navigate("/get-started")
            }}
        >
            {paymentComplete ?
                <div className="flex flex-col items-center mt-8">
                    <div className="flex flex-col items-center p-8 max-w-lg">
                        <Center mb={'lg'} mt={'md'}>
                            <Card
                                variant={'subtle'}
                                withBorder
                                shadow="sm"
                                radius="md"
                                style={{}}
                                maw={400}
                            >
                                <Stack>
                                    <ConfirmationBox
                                        title={"Payment complete"}
                                        subTitle={`Your payment has been received. You may now proceed to view your analysis results`}
                                        btnLabel={"See my results"}
                                        onBtnClick={() => {
                                            navigate(`/results/${id}`)
                                        }}
                                    />
                                </Stack>
                            </Card>
                        </Center>
                    </div>
                </div>
                :
                <PaymentForm analysis={analysis} onFailed={onFailed} onSuccess={onSuccess}/>
            }

        </PageContainer>
    )
}
