import {PageContainer} from "../components/PageContainer";
import {useState} from "react";
import {CameraView} from "../components/CameraView";
import {LoadingElement} from "../components/LoadingElement";
import {ConfirmationBox} from "../components/ConfirmationBox";
import {useNavigate} from "react-router-dom";
import {useCreateNewSkinAnalysisRequest} from "../hooks/api/useSkinAnalysisApi";
import {getFormarttedNumber} from "../utils/utils";
import {Alert, Button, Card, Center, Stack, Text} from "@mantine/core";
import {CenteredLogoWithTitle} from "../components/CenteredLogoWithTitle";
import {IconInfoCircle} from '@tabler/icons-react';

export const TakeSelfie = () => {
    const navigate = useNavigate();
    const mutation = useCreateNewSkinAnalysisRequest()

    const [enableCamera, setEnableCamera] = useState(false)
    const [analysis, setAnalysis] = useState(null)

    const handleEnablePermission = () => {
        setEnableCamera(true)
    }

    const onSubmit = (base64) => {
        setEnableCamera(false)
        setAnalysis(null)

        mutation.mutate(base64, {
            onSuccess: (res) => {
                setAnalysis(res.data)
            },
            onError: () => {

            }
        })
    }

    const onCompletePayment = () => {

    }

    const onCancel = () => {
        if (enableCamera) {
            setEnableCamera(false)
        } else {
            navigate("/get-started")
        }
    }

    return (
        <>
            {enableCamera &&
                <>
                    <CameraView onSubmit={onSubmit} onCancel={onCancel}/>
                    {/*<FullscreenCameraView onSubmit={onSubmit} onCancel={onCancel}/>*/}
                </>
            }

            {!enableCamera &&
                <PageContainer
                    showClose
                    onClose={onCancel}
                    className={enableCamera ? 'bg-black' : ''}
                    showHeader={!enableCamera}
                >
                    {(analysis || mutation.isPending) &&
                        <div className="flex flex-col items-center mt-8">
                            <div className="flex flex-col items-center p-8 max-w-lg">
                                <Center mb={'lg'} mt={'md'}>
                                    <Card
                                        variant={'subtle'}
                                        withBorder
                                        shadow="sm"
                                        radius="md"
                                        style={{}}
                                        maw={400}
                                    >
                                        <Stack>
                                            {mutation.isPending &&
                                                <LoadingElement
                                                    title={"Preparing your photo"}
                                                    subTitle={"Please keep this window open"}
                                                />
                                            }

                                            {analysis &&
                                                <ConfirmationBox
                                                    title={"Upload complete"}
                                                    subTitle={`Please complete payment of ${analysis?.currency} ${getFormarttedNumber(analysis?.amount)} to see your analysis results`}
                                                    btnLabel={"Complete payment"}
                                                    onBtnClick={() => {
                                                        navigate(`/payment/${analysis?.tracking_key}`)
                                                    }}
                                                />
                                            }
                                        </Stack>
                                    </Card>
                                </Center>
                            </div>
                        </div>
                    }

                    {(!analysis && !mutation.isPending) &&
                        <>
                            <Center mb={'lg'} mt={'md'}>
                                <Card
                                    variant={'subtle'}
                                    withBorder
                                    shadow="sm"
                                    radius="md"
                                    style={{}}
                                    maw={400}
                                >
                                    <Stack>
                                        <CenteredLogoWithTitle label={"Allow camera permissions"}/>

                                        <Text className={'mt-8 text-center'}>
                                            For an optimal experience, our Virtual Skin Analyzer needs permission to use
                                            your device
                                            camera.
                                        </Text>

                                        <Button
                                            className={'w-full mt-5'}
                                            size={'lg'}
                                            onClick={handleEnablePermission}
                                        >
                                            Enable permissions
                                        </Button>

                                        <Button
                                            variant={'subtle'}
                                            className={'w-full'}
                                            onClick={() => {
                                                navigate(-1)
                                            }}
                                        >
                                            Go back
                                        </Button>

                                        <Alert
                                            variant="outlined"
                                            title="Privacy notice"
                                            icon={<IconInfoCircle/>}
                                            mt={'md'}
                                        >
                                            <Text size={'sm'}>
                                                Please note that your image will be deleted after the analysis.
                                            </Text>
                                        </Alert>
                                    </Stack>
                                </Card>
                            </Center>
                        </>
                    }
                </PageContainer>
            }
        </>


    )
}
