import {Logo} from "./Logo";
import {twMerge} from "tailwind-merge";
import {Link} from "react-router-dom";
import {ActionIcon} from "@mantine/core";
import {IconX} from "@tabler/icons-react";

export const Header = ({showClose, onClose, className}) => {
    return (
        <div className={twMerge("w-full flex flex-row items-center justify-between py-8", className)}>
            <Link to={"/"}>
                <Logo isDark/>
            </Link>
            <div>
                {showClose &&
                    <ActionIcon onClick={onClose}>
                        <IconX/>
                    </ActionIcon>
                }
            </div>
        </div>
    )
}
