import {Stack, Title} from "@mantine/core";
import {Logo} from "./Logo";

export const CenteredLogoWithTitle = ({label, showLogo = false}) => {
    return (
        <Stack justify={'center'} align={'center'} mb={'md'}>
            {showLogo && <Logo isDark/>}

            <Title fw={'bold'} order={2} style={{textAlign: 'center'}}>
                {label}
            </Title>
        </Stack>
    )
}
