import {twMerge} from "tailwind-merge";
import {Button, Text} from "@mantine/core";

export const ConfirmationBox = ({title, subTitle, titleClass, subTitleClass, btnLabel, onBtnClick}) => {
    return (
        <div className="flex flex-col items-center justify-center">
            <img src={'/img/check.svg'}/>

            <Text fw={'bold'} className={twMerge('text-white mt-6 text-xl', titleClass)}>
                {title}
            </Text>

            <Text className={twMerge('text-white mt-4 text-center', subTitleClass)}>
                {subTitle}
            </Text>

            <Button onClick={onBtnClick} className={'w-full mt-8'} size={'md'}>
                {btnLabel}
            </Button>
        </div>
    )
}
