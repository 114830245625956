import {BoldText} from "./BoldText";
import {Text} from "./Text";
import {twMerge} from "tailwind-merge";

export const LoadingElement = ({title, subTitle, titleClass, subTitleClass}) => {
    return (
        <div className="flex flex-col items-center justify-center mt-12">
            <div className={'loader'}/>

            <BoldText className={twMerge('text-dark mt-6 text-xl', titleClass)}>
                {title}
            </BoldText>

            <Text className={twMerge('text-dark mt-2', subTitleClass)}>
                {subTitle}
            </Text>
        </div>
    )
}
