import {Anchor, Center, Group} from "@mantine/core";

export const Footer = () => {
    return (
        <>
            <Center mt={'lg'}>
                <Group p={'md'}>
                    <Anchor href="https://tuibeauty.com/policies/privacy-policy" target="_blank" size={'sm'}>
                        Privacy Policy
                    </Anchor>
                    <Anchor href="https://tuibeauty.com/policies/terms-and-conditions" target="_blank" size={'sm'}>
                        Terms and Conditions
                    </Anchor>
                </Group>
            </Center>
        </>
    )
}
