import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import NotificationsProvider from "./hooks/utils/useNotifications";
import {colorsTuple, createTheme, MantineProvider} from "@mantine/core";
import {Layout} from "./layouts/Layout";

const queryClient = new QueryClient()

function App() {

    const theme = createTheme({
        fontFamily: '"Josefin Sans", sans-serif',
        primaryColor: 'tui-primary',
        colors: {
            'tui-primary': colorsTuple("#000000"),
        }
    });

    return (
        <div className={'h-full'}>
            <QueryClientProvider client={queryClient}>
                <NotificationsProvider>
                    <MantineProvider theme={theme}>
                        <Layout/>
                    </MantineProvider>
                </NotificationsProvider>
            </QueryClientProvider>
        </div>
    );
}

export default App;
