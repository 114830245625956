import {BrowserRouter, Route, Routes} from "react-router-dom";
import {MainLayout} from "./MainLayout";
import {Home} from "../pages/Home";
import {GetStarted} from "../pages/GetStarted";
import {TakeSelfie} from "../pages/TakeSelfie";
import {Payment} from "../pages/Payment";
import {Results} from "../pages/Results";

export const Layout = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<MainLayout/>}>
                    <Route index element={<Home/>}/>
                    <Route path="get-started"
                           element={<GetStarted/>}/>
                    <Route path="take-selfie"
                           element={<TakeSelfie/>}/>
                    <Route path="payment/:id"
                           element={<Payment/>}/>
                    <Route path="results/:id"
                           element={<Results/>}/>
                </Route>
            </Routes>
        </BrowserRouter>
    )
}
