import api from "../../api/api";
import {Endpoints} from "../../api/endpoints";
import {useMutation, useQuery} from "@tanstack/react-query";
import {useNotifications} from "../utils/useNotifications";
import {STRINGS} from "../../constants/strings";
import {buildUrl} from "../../utils/utils";

export const useCreateNewSkinAnalysisRequest = () => {
    const {showErrorNotification} = useNotifications()
    return useMutation({
        mutationFn: async (base64) => {
            const {data} = await api.post(Endpoints.NEW_SKIN_ANALYSIS, {base64})
            return data
        },
        onError: (res) => {
            showErrorNotification(res?.response?.data?.message || STRINGS.API_ERROR_MESSAGE)
        }
    })
}

export const usePayForSkinAnalysis = () => {
    const {showErrorNotification} = useNotifications()
    return useMutation({
        mutationFn: async ({id, phone, paymentType, email}) => {
            const {data} = await api.post(Endpoints.SKIN_ANALYSIS_PAYMENT, {
                phone,
                id,
                payment_method: paymentType,
                email
            })
            return data
        },
        onError: (res) => {
            showErrorNotification(res?.response?.data?.message || STRINGS.API_ERROR_MESSAGE)
        }
    })
}

export const useEmailRecommendation = () => {
    const {showErrorNotification} = useNotifications()
    return useMutation({
        mutationFn: async ({id, email}) => {
            const {data} = await api.post(Endpoints.SKIN_ANALYSIS_EMAIL, {email, id})
            return data
        },
        onError: (res) => {
            showErrorNotification(res?.response?.data?.message || STRINGS.API_ERROR_MESSAGE)
        }
    })
}

export const useFindSkinAnalysis = (id) => {
    return useQuery({
        queryKey: [''],
        queryFn: async ({signal}) => {
            const {data} = await api.get(buildUrl(Endpoints.FIND_SKIN_ANALYSIS, {id}), {signal})
            return data
        },
    })
}

export const useFindSkinAnalysisAfterPayment = (id, enable) => {
    return useQuery({
        queryKey: [],
        queryFn: async ({signal}) => {
            const {data} = await api.get(buildUrl(Endpoints.FIND_SKIN_ANALYSIS, {id}), {signal})
            return data
        },
        enabled: enable,
        refetchInterval: 3000,
        refetchIntervalInBackground: true,
    })
}

export const useFindSkinAnalysisResults = (id, enable) => {
    return useQuery({
        queryKey: [],
        queryFn: async ({signal}) => {
            const {data} = await api.get(buildUrl(Endpoints.FIND_SKIN_ANALYSIS, {id}), {signal})
            return data
        },
        enabled: enable,
        refetchInterval: 3000,
        refetchIntervalInBackground: true,
    })
}
