import {useFindSkinAnalysisAfterPayment, usePayForSkinAnalysis} from "../hooks/api/useSkinAnalysisApi";
import {useForm} from "react-hook-form";
import {ANALYSIS_STATUS} from "../constants/status";
import {useEffect, useState} from "react";
import {getFormarttedNumber} from "../utils/utils";
import {ListItem} from "./ListItem";
import {Input} from "./Input";
import {LoadingElement} from "./LoadingElement";
import {ButtonToggle} from "./ButtonToggle";
import {Button, Card, Center, List, Stack, Text, ThemeIcon} from "@mantine/core";
import {IconCircleCheck} from "@tabler/icons-react";
import {CenteredLogoWithTitle} from "./CenteredLogoWithTitle";
import {TextInput} from "./TextInput";
import {validationPatterns} from "./forms.utils";
import {RadioSelector} from "./RadioSelector";

export const PaymentForm = ({onSuccess, onFailed, analysis}) => {
    const analysisID = analysis?.tracking_key || ""

    const [startPoll, setStartPoll] = useState(false)
    const [paymentType, setPaymentType] = useState('mpesa')

    const mutation = usePayForSkinAnalysis()
    const analysisQuery = useFindSkinAnalysisAfterPayment(analysisID, startPoll)

    const {
        control,
        register,
        handleSubmit,
        formState: {errors, isValid},
    } = useForm({
        defaultValues: {
            phone: '+254',
            paymentType: 'mpesa',
            email: ''
        },
        mode: 'onChange',
    })

    const onSubmit = ({phone, email}) => {
        if (!analysisID) {
            return
        }

        mutation.mutate({phone, id: analysisID, paymentType, email}, {
            onSuccess: (data) => {
                if (paymentType === "card") {
                    window.location.assign(data.data)
                }
                // start polling for payment status
                setStartPoll(true)
            }
        })
    }

    if (analysisQuery.data?.data?.payment_status === ANALYSIS_STATUS.PAID) {
        onSuccess()
    }

    if (analysisQuery.data?.data?.payment_status === ANALYSIS_STATUS.FAILED) {
        onFailed()
    }

    useEffect(() => {
        setStartPoll(false)
    }, [analysisID])

    const isAwaitingPayment = startPoll && analysisQuery.data?.data?.payment_status === ANALYSIS_STATUS.PENDING

    const isLoading = mutation.isPending || analysisQuery.isLoading || isAwaitingPayment

    return (
        <>
            <div className="flex flex-col items-center">
                {isLoading &&
                    <LoadingElement
                        title={"Waiting for payment"}
                        subTitle={"Please keep this window open while we process your payment"}
                    />
                }

                {!isLoading &&
                    <>
                        <Center mb={'lg'} mt={'md'}>
                            <Card
                                variant={'subtle'}
                                withBorder
                                shadow="sm"
                                radius="md"
                                style={{}}
                                maw={400}
                            >
                                <Stack>
                                    <CenteredLogoWithTitle label={"Complete your payment"}/>

                                    <Text className={'mt-8 text-center'}>
                                        Pay {analysis?.currency} {getFormarttedNumber(analysis.amount)}
                                    </Text>

                                    <RadioSelector
                                        label={"Select a payment method"}
                                        hint={"Choose how you would like to pay."}
                                        data={[
                                            {
                                                name: "M-PESA",
                                                description: "You will receive a prompt on your phone to complete payment.",
                                                value: "mpesa",
                                            },
                                            {
                                                name: "Card",
                                                description: "You will be redirected to ",
                                                value: "card",
                                            }
                                        ]}
                                        value={paymentType}
                                        setValue={setPaymentType}
                                    />


                                    {paymentType === "mpesa" &&
                                        <>
                                            <form onSubmit={handleSubmit(onSubmit)} className={'w-full'}>
                                                <TextInput
                                                    control={control}
                                                    name={'email'}
                                                    label={"Email address"}
                                                    placeholder={"Your email address"}
                                                    hint={"Your results and recommendations will be sent to this email."}
                                                    errors={errors}
                                                    required={true}
                                                    rules={{
                                                        pattern: validationPatterns.email
                                                    }}
                                                />

                                                <TextInput
                                                    control={control}
                                                    name={'phone'}
                                                    label={"M-PESA mobile number"}
                                                    placeholder={"Start with country code"}
                                                    errors={errors}
                                                    required={true}
                                                />

                                                <Button
                                                    type="submit"
                                                    disabled={!isValid || mutation.isPending}
                                                    mt={'md'}
                                                    fullWidth
                                                >
                                                    Confirm and Pay
                                                </Button>
                                            </form>
                                        </>
                                    }

                                    {paymentType === "card" &&
                                        <>
                                            <form onSubmit={handleSubmit(onSubmit)} className={'w-full'}>
                                                <TextInput
                                                    control={control}
                                                    name={'email'}
                                                    label={"Email address"}
                                                    placeholder={"Your email address"}
                                                    hint={"Your results and recommendations will be sent to this email."}
                                                    errors={errors}
                                                    required={true}
                                                    rules={{
                                                        pattern: validationPatterns.email
                                                    }}
                                                />

                                                <Button
                                                    type="submit"
                                                    fullWidth
                                                    className={'mt-8'}
                                                >
                                                    Proceed to payment
                                                </Button>
                                            </form>
                                        </>
                                    }

                                </Stack>
                            </Card>
                        </Center>
                    </>
                }
            </div>
        </>
    )
}
