import {PageContainer} from "../components/PageContainer";
import {useNavigate} from "react-router-dom";
import {Button, Card, Center, List, Stack, Text, ThemeIcon, Title} from "@mantine/core";
import {IconCircleCheck} from '@tabler/icons-react';
import {Logo} from "../components/Logo";
import {CenteredLogoWithTitle} from "../components/CenteredLogoWithTitle";

export const GetStarted = () => {
    const navigate = useNavigate();

    return (
        <PageContainer
            showClose
            onClose={() => {
                navigate("/")
            }}
        >
            <Center mb={'lg'} mt={'md'}>
                <Card
                    variant={'subtle'}
                    withBorder
                    shadow="sm"
                    radius="md"
                    style={{}}
                    maw={360}
                >
                    <Stack>
                        <CenteredLogoWithTitle label={"Start your analysis"}/>

                        <List
                            spacing="xs"
                            size="md"
                            center
                            icon={
                                <ThemeIcon color="teal" size={24} radius="xl">
                                    <IconCircleCheck size={16}/>
                                </ThemeIcon>
                            }
                        >
                            <List.Item>Remove make up and glasses.</List.Item>
                            <List.Item>Pull your hair back.</List.Item>
                            <List.Item>Face camera and keep expression neutral.</List.Item>
                            <List.Item>Stay in well-lit, natural light.</List.Item>
                        </List>

                        <Button
                            className={'w-full mt-5'}
                            size={'lg'}
                            onClick={() => {
                                navigate("/take-selfie")
                            }}
                        >
                            Take a selfie
                        </Button>

                        <Button
                            variant={'subtle'}
                            className={'w-full'}
                            onClick={() => {
                                navigate(-1)
                            }}
                        >
                            Go back
                        </Button>
                    </Stack>
                </Card>
            </Center>
        </PageContainer>
    )
}
