import {Box} from "@mui/material";
import {Text} from "@mantine/core"

export const ResultsHighlight = ({
                                     title,
                                     list = [],
                                     style = {},
                                     isPositive
                                 }) => {
    return (
        <Box
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                ...style,
            }}
        >
            <Text
                fw={'bold'}
                color={isPositive ? "green" : "red"}
            >
                {title}
            </Text>

            {list?.map((item, idx) => {
                return (
                    <div key={idx} className={'flex flex-col mb-8'}>
                        <Text
                            fw={'bold'}
                            size={'lg'}
                        >
                            {item.title}
                        </Text>

                        <Text
                            mt={'md'}
                        >
                            {item.description}
                        </Text>
                    </div>
                )
            })}
        </Box>
    )
}
